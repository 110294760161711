import axios from '@/api/api-request'

const projectUrl = '/webCoorperationCompany'

// 茶园基地管理-获取分页列表
export const list = (data) => {
  return axios.request({
    url: projectUrl + '/list.do',
    method: 'post',
    data: data
  })
}
// 茶园基地管理-获取分页列表
export const getLists = (data) => {
  return axios.request({
    url: projectUrl + '/getLists.do',
    method: 'post',
    data: data
  })
}
// 茶园基地管理-获取分页列表
export const getInfo = (data) => {
  return axios.request({
    url: projectUrl + '/getInfo.do',
    method: 'post',
    data: data
  })
}
