<template>
  <div class="ziye_gbeij">
    <div class="px1200">
      <div class="dangq_luj px1200"><span><my-link href="/home-layout">首页</my-link> > <my-link href="/reorgs">茶园基地</my-link></span></div>
      <div class="chanp_biapt">
        <h2>茶园基地</h2>
        <!--        <p>善于运用身体自愈力自我调养的病人，则康复得更快</p>-->
      </div>
      <div class="nimen_nimen_Ge nimen_nimen_Ge_ziy nimen_nimen_Ge_2">
        <ul>
          <li v-for="coorCompanys in coorCompanys" :key="coorCompanys.id">
            <img :src="httpPath + coorCompanys.logo" alt="">
          </li>
        </ul>
      </div>
      <div class="nimen_nimen_Ge nimen_nimen_Ge_ziy">
        <ul>
          <li v-for="item in coorCompanys1" :key="item.id" :title="item.title">
            <img :src="httpPath + item.logo" alt="">
          </li>
        </ul>
      </div>
    </div>
  </div>
<!--    <div class="wrap-lg">-->
<!--      <div class="">-->
<!--        <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">茶园基地</span></div>-->
<!--      </div>-->
<!--      <div class="reorgs_center">-->
<!--        <div v-for="coorCompanys in coorCompanys" :key="coorCompanys.id">-->
<!--          <div style="margin: 3px; ">-->
<!--            <img :src="httpPath + coorCompanys.logo"  style="width: 100%; height: 500px;" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="reorgs_foot">-->
<!--        <div class="list-unit">-->
<!--          <div class="item-unit" v-for="item in coorCompanys1" :key="item.id" :title="item.title">-->
<!--            <img :src="httpPath + item.logo">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
</template>

<script>
import { getLists } from '@/api/recommend/coorCompany'
export default {
  name: 'index',
  data () {
    return {
      httpPath: '',
      currIndex: '',
      coorCompanys: [],
      coorCompanys1: []
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.initData()
  },
  methods: {
    initData: function () {
      // 初始化数据
      let params = {}
      getLists(params).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.coorCompanys = result.bizData.slice(0, 3)
          this.coorCompanys1 = result.bizData.slice(4, 10)
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reorgs_center {
  background: #4f080e;
  border: 1px solid #f6b95e;
  width: 1224px;
}
.reorgs_foot {
  background: #4f080e;
  border: 1px solid #f6b95e;
  margin-top: 30px;
  width: 1224px;
  .list-unit {
    overflow: hidden;
    margin: 20px;
    .item-unit {
      background: #ffffff;
      width: 390px;
      height: 300px;
      float: left;
      text-align: center;
      margin: 2px;
      &:hover {
        box-shadow: 0px 0px 15px 0px rgba(2, 91, 75, 0.2);
      }
      img{
       width: 100%;
        height: 100%;
      }
    }
  }
}
.nimen_nimen_Ge_2 ul li {
  width: 100%;
  img {
    width: 100%;
    height: 500px;
  }
}
</style>
